import { createStore } from "vuex";

export default createStore({
    state: {
        baseURL: "https://v-energy.web-flames.com/api/delivery/",
        // baseURL: "http://127.0.0.1:8000/api/delivery/",
        // reportURL: "http://127.0.0.1:8000/api/daily-reports/",
        reportURL: "https://v-energy.web-flames.com/api/daily-reports/",
    },
});
