<template>
  <component :is="layout" />
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';

export default {
  data() {
    return {
      layout: null,
    };
  },
  components: {
    DefaultLayout,
    EmptyLayout
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "EmptyLayout"
      }
    },
  },
};
</script>