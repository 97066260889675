<template>
    <header class="bg-white">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1">
                <a href="#" class="-m-1.5 p-1.5">
                    <span class="sr-only">V-energy</span>
                    <img class="h-20 w-auto" src="../assets/logo.png" alt="logo" />
                </a>
            </div>
            <div class="flex lg:hidden">
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <PopoverGroup class="hidden lg:flex lg:gap-x-12">
                <router-link class="text-sm font-semibold leading-6 text-gray-900" to="/">Today's
                    Transactions</router-link>
                <!-- <router-link class="text-sm font-semibold leading-6 text-gray-900" to="/return-transactions">Return
                    Transactions</router-link> -->
                <router-link class="text-sm font-semibold leading-6 text-gray-900" to="/transaction-history">Transaction
                    History</router-link>
            </PopoverGroup>
            <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                <span @click="logOut" class="text-sm font-semibold leading-6 text-white"
                    style="background-color: #7dc445; padding: 5px 20px; border-radius: 10px; cursor: pointer;">Logout</span>
            </div>
        </nav>
        <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10">
                <DialogPanel
                    class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div class="flex items-center justify-between">
                        <a href="#" class="-m-1.5 p-1.5">
                            <span class="sr-only">Your Company</span>
                            <img class="h-20 w-auto" src="../assets/logo.png" alt="logo" />
                        </a>
                        <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                            <span class="sr-only">Close menu</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <router-link to="/"
                                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900">Today's
                                    Transactions</router-link>
                                <!-- <router-link to="/return-transactions"
                                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900">Return
                                    Transactions</router-link> -->
                                <router-link to="/transaction-history"
                                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900">Transaction
                                    History</router-link>
                            </div>
                            <div class="py-6">
                                <a @click="logOut"
                                    class="-mx-3 block rounded-lg px-3 py-2.5 text-white font-semibold leading-7 text-gray-900"
                                    style="background-color: #7dc445; padding: 5px 20px; border-radius: 10px; cursor: pointer;">
                                    Logout
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    </header>
</template>

<script setup>
import { ref } from 'vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const mobileMenuOpen = ref(false)
</script>

<script>
export default {
    methods: {
        logOut() {
            localStorage.clear();
            location.reload();
        }
    },
}
</script>

<style>
.active {
    color: #7dc445;
    text-decoration: underline;
}
</style>