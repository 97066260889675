import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/login",
        name: "login",
        meta: {
            requiresNoAuth: true,
            layout: "EmptyLayout",
        },
        component: () => import("../views/LoginView.vue"),
    },
    {
        path: "/",
        name: "home",
        props: true,
        meta: {
            requiresAuth: true,
            layout: "DefaultLayout",
        },
        component: () => import("../views/HomeView.vue"),
    },
    {
        path: "/transaction-details",
        name: "transaction-details",
        meta: {
            requiresAuth: true,
            layout: "DefaultLayout",
        },
        component: () => import("../views/DetailsView.vue"),
    },
    {
        path: "/receipt",
        name: "receipt",
        component: () => import("../views/ReceiptView.vue"),
    },
    {
        path: "/transaction-history",
        name: "transaction-history",
        meta: {
            requiresAuth: true,
            layout: "DefaultLayout",
        },
        component: () => import("../views/HistoryView.vue"),
    },
    {
        path: "/return-transactions",
        name: "return-transactions",
        meta: {
            requiresAuth: true,
            layout: "DefaultLayout",
        },
        component: () => import("../views/ReturnsView.vue"),
    },
    {
        path: "/daily-report",
        name: "daily-report",
        component: () => import("../views/ReportView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    const userAuthenticated = localStorage.getItem("agent_token") !== null;
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresNoAuth = to.matched.some(
        (record) => record.meta.requiresNoAuth
    );

    if (requiresAuth && !userAuthenticated) {
        next("/login");
    } else if (requiresNoAuth && userAuthenticated) {
        next("/");
    } else {
        next();
    }
});

export default router;
