<template>
    <Header></Header>
    <router-view></router-view>
</template>

<script>
import Header from '@/components/Header.vue';
import swal from 'sweetalert';

export default {
    components: {
        Header
    }
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
</style>